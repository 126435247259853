package space.stanton.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text
import space.stanton.components.theme.ColorTokens

@Composable
fun NavScaffold(content: @Composable () -> Unit) {
    Column(
        Modifier
            .fillMaxSize()
            .background(ColorTokens.PageBackground),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top,
    ) {
        Column(
            Modifier
                .padding(2.cssRem)
                .width(80.vw)
                .attrsModifier {
                    style { color(ColorTokens.Body) }
                }
        ) {

            Column(
                Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.End
            ) {
                Row(Modifier.fontSize(50.pt)) {
                    Text("stanton.space")
                }
                Row(
                    Modifier
                        .fillMaxWidth()
                        .fontSize(40.pt)
                        .padding(top = 2.cssRem, bottom = 1.cssRem),
                    horizontalArrangement = Arrangement.End
                ) {
                    NavLink("/", "Home")
                    NavLink("/pages/about_me", "About me")
                }
            }
            Column(
                Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.Start
            ) {
                content()
            }
        }
    }
}
