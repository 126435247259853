package space.stanton.pages.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun About_mePage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("pages/about_me.md", mapOf("title" to listOf("About me")))) {
        space.stanton.components.layouts.AboutMe {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hi. I'm Stanton")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("My full name is actually Steven Stanton, but everyone just calls me Stanton.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I am both a professional and a hobbyist developer. My main focus has been Android apps. I’m based in Manchester, UK.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This is a blog about code stuff I'm learning and code stuff I would like to share. I hope you find some of it useful.")
            }
        }
    }
}
