package space.stanton.components.theme

object ColorTokens {

    val PageBackground = Palette.MidnightBlue
    val Heading = Palette.SoftLightBlue

    val Body = Palette.SoftLightBlue
    val Subtitle = Palette.FadedLightBlue

    val CodeContainer = Palette.ScreenBlue

    object Link {
        val Neutral = Palette.DullCream
        val Hover = Palette.BrightCream
        val Active = Palette.BrightCream
    }
}


