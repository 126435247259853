package space.stanton.pages.posts

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun Bp_2024_08_11_where_it_all_beganPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("posts/bp_2024_08_11_where_it_all_began.md", mapOf("title" to listOf("Where it all began"), "date" to listOf("2024_08_11")))) {
        space.stanton.components.layouts.BlogPost {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("My first experience of coding was on the Sinclair ZX Spectrum +2.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I was probably about 7 years old, and the world of computing seemed like an avenue of infinite possibilities.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This machine was part of a generation of home computers  which were probably primarily bought for gaming, but which also marketed themselves as educational multi-purpose tools, before the later surge in popularity of gaming-only consoles.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("The Spectrum shipped with an inbuilt coding editor for writing small programs with Sinclair Basic. There was a manual which I remember attempting to work through, but most of it went way over my head.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("There were also pages of code in magazines which you were encouraged to copy out verbatim.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("I managed to write some very small adventure games. gradually figured out what variables were and how to use if statements.")
            }
        }
    }
}
