package space.stanton.components.utils

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobwebx.markdown.markdown
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import kotlinx.datetime.format.DayOfWeekNames
import kotlinx.datetime.format.MonthNames
import kotlinx.datetime.format.char


object BlogPostFields {
    const val title = "title"
    const val date = "date"
}

val dateFormat = LocalDate.Format {
    dayOfWeek(DayOfWeekNames.ENGLISH_FULL)
    chars(" ")
    dayOfMonth()
    chars(" ")
    monthName(MonthNames.ENGLISH_ABBREVIATED)
    chars(", ")
    year()
}

@Composable
fun PageContext.field(key: String) =
    markdown?.frontMatter?.let { it[key] }?.single()


val mdDateFormat  = LocalDate.Format {
    year()
    char('_')
    monthNumber()
    char('_')
    dayOfMonth()
}

@Composable
fun PageContext.date(): String = LocalDate
    .parse(field(BlogPostFields.date).orEmpty(), mdDateFormat)
    .format(dateFormat)

@Composable
fun PageContext.title(): String = field(BlogPostFields.title).orEmpty()
