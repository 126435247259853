package space.stanton.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.selectors.visited
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Text
import space.stanton.components.theme.ColorTokens

@Composable
fun NavLink(url: String, content: String) {
    Link(url, modifier = NavLinkStyle.toModifier()) {
        Text(content)
    }
}


val NavLinkStyle = CssStyle {
    base {
        Modifier
            .color(ColorTokens.Link.Neutral)
            .fontSize(20.pt)
            .padding(left = 2.cssRem)
            .transition(
                arrayOf(
                    Transition.of("scale", 300.ms),
                    Transition.of("padding", 300.ms),
                    Transition.of("color", 300.ms),
                )
            )
    }
    visited {
        Modifier.color(ColorTokens.Link.Neutral)
    }

    hover {
        Modifier
            .color(ColorTokens.Link.Hover)
            .scale(1.4f)
            .textDecorationLine(TextDecorationLine.Underline)
            .padding(left = 2.5.cssRem, right = 0.5.cssRem)
    }
}