package space.stanton.pages.posts

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun Bp_2024_06_14_helloPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("posts/bp_2024_06_14_hello.md", mapOf("title" to listOf("Hello world"), "date" to listOf("2024_06_14")))) {
        space.stanton.components.layouts.BlogPost {
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("This is the first post in my blog. Hopefully there is more coming soon...")
            }
        }
    }
}
