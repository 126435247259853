package space.stanton.pages.posts

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun Bp_2024_08_11_why_i_love_kotlinPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("posts/bp_2024_08_11_why_i_love_kotlin.md", mapOf("title" to listOf("Why I love Kotlin"), "date" to listOf("2024_08_11")))) {
        space.stanton.components.layouts.BlogPost {
        }
    }
}
