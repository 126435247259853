package space.stanton.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text
import space.stanton.components.theme.BlogStyle
import space.stanton.components.theme.ColorTokens
import space.stanton.components.utils.title
import space.stanton.components.widgets.NavScaffold
import space.stanton.components.widgets.SocialIcon

@Composable
fun AboutMe(content: @Composable () -> Unit) {
    NavScaffold {
        val ctx = rememberPageContext()
        Row(
            BlogStyle.toModifier().fillMaxWidth(90.percent)
        ) {
            Column(Modifier.padding(2.cssRem)) {
                Image(
                    "/images/profile.jpg",
                    modifier = Modifier.width(15.cssRem).borderRadius(1.cssRem).border {
                        style(LineStyle.Solid)
                        color(ColorTokens.Heading)
                        width(5.px)
                    },
                )
            }
            Column(Modifier.weight(1).padding(2.cssRem)) {
                Box(Modifier.fontSize(30.pt).color(ColorTokens.Heading)) {
                    Text(ctx.title())
                }
                content()
            }
        }

        Row(
            Modifier.padding(1.cssRem).fillMaxWidth(80.percent),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Box(Modifier.fontSize(20.pt)) {

                Text("Find me online:")
            }
            SocialIcon("Github", "/images/github.svg", "https://github.com/stantronic")
            SocialIcon("Twitter", "/images/x.svg", "https://twitter.com/stantronic")
            SocialIcon("LinkedIn", "/images/linkedin.svg", "https://linkedin.com/in/stantronic")
        }
    }
}


