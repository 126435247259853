import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("/")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { space.stanton.pages.HomePage() }
        ctx.router.register("/pages/about_me") { space.stanton.pages.pages.About_mePage() }
        ctx.router.register("/posts/bp_2024_06_14_hello") {
                space.stanton.pages.posts.Bp_2024_06_14_helloPage() }
        ctx.router.register("/posts/bp_2024_08_11_where_it_all_began") {
                space.stanton.pages.posts.Bp_2024_08_11_where_it_all_beganPage() }
        ctx.router.register("/posts/bp_2024_08_11_why_i_love_kotlin") {
                space.stanton.pages.posts.Bp_2024_08_11_why_i_love_kotlinPage() }

    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.ALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("blog", space.stanton.components.theme.BlogStyle)
        ctx.theme.registerStyle("nav-link", space.stanton.components.widgets.NavLinkStyle)
        space.stanton.components.theme.initSilk(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "blog"))
    renderComposable(rootElementId = "root") {
        space.stanton.components.theme.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
