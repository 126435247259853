package space.stanton.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Code
import org.jetbrains.compose.web.dom.Pre
import space.stanton.components.theme.ColorTokens

@Composable
fun KotlinCodeBlock(text: String) {

    val content = remember(true) {
        val c = hljs.highlight(text, HighlightJsOptionsImp(language = "kt"))
        c["value"].toString()
    }
    Box(
        Modifier
            .padding(2.cssRem)
            .backgroundColor(ColorTokens.CodeContainer)
            .borderRadius(1.cssRem)
    ) {

        Pre(attrs = {
            classes("language-kotlin").also {
                style {
                    fontFamily("Jetbrains Mono")
                    fontSize(20.pt)
                    background("transparent")
                }
            }
        }) {
            Code(
                attrs = {
                    ref { htmlElement ->
                        htmlElement.innerHTML = content
                        onDispose { }
                    }
                }
            ) {

            }
        }
    }
}