package space.stanton.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text
import space.stanton.components.theme.ColorTokens

@Composable
fun SocialIcon(label: String, icon: String, url: String) {
    Link(url, modifier = NavLinkStyle.toModifier()) {
        Row(
            Modifier.padding(1.cssRem),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Box(Modifier.padding(right = 0.5.cssRem)) {
                Box(
                    Modifier
                        .padding(0.3.cssRem)
                        .borderRadius(10.px)
                        .backgroundColor(ColorTokens.Link.Neutral)
                ) {
                    Image(icon)
                }
            }
            Text(label)
        }
    }
}