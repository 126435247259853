package space.stanton.components.theme

import org.jetbrains.compose.web.css.Color

@Suppress("SpellCheckingInspection")
object Palette {
    val MidnightBlue = "150f29".hexColor
    val ScreenBlue = "191646".hexColor
    val TotalWhite = "ffffff".hexColor
    val MintGreen = "8df0e1".hexColor
    val SoftLightBlue = "77d1ff".hexColor
    val FadedLightBlue = "81cff7a8".hexColor
    val Maroon = "cc6aa1bd".hexColor
    val Cream = "d3dba9".hexColor
    val DullCream = "bbbf99".hexColor
    val BrightCream = "faffd3".hexColor
}


private val String.hexColor
    get() = Color("#$this")


