package space.stanton.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Text
import space.stanton.components.theme.BlogStyle
import space.stanton.components.theme.ColorTokens
import space.stanton.components.utils.date
import space.stanton.components.utils.title
import space.stanton.components.widgets.NavScaffold
import com.varabyte.kobweb.silk.style.toModifier

@Composable
fun BlogPost(content: @Composable () -> Unit) {
    NavScaffold {
        val ctx = rememberPageContext()
        Column(
            modifier = BlogStyle.toModifier()
        ) {
            Column(
                Modifier
                    .fillMaxWidth()
                    .color(ColorTokens.Heading)
                    .margin(bottom = 2.cssRem),
            ) {
                Box(Modifier.fontSize(30.pt).color(ColorTokens.Heading)) {
                    Text(ctx.title())
                }
                Box(Modifier.color(ColorTokens.Subtitle).padding(top = 0.5.cssRem)) {
                    Text(ctx.date())
                }
            }
            content()
        }
    }
}

