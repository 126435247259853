package space.stanton.components.theme

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.style.CssStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.pt

val BlogStyle = CssStyle {
    base {
        Modifier
            .color(ColorTokens.Body)
            .padding(2.cssRem)
            .fontSize(16.pt)
            .background(ColorTokens.PageBackground)
    }
    cssRule("h1") {
        Modifier.color(ColorTokens.Heading)
    }
}