package space.stanton.components.theme

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase

@InitSilk
fun initSilk(context: InitSilkContext) {
    context.stylesheet.run {
        registerStyleBase("body") {
            Modifier.fontFamily("Averia Sans Libre", "cursive")
        }
    }
}