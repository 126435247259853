package space.stanton.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import space.stanton.components.widgets.NavLink
import space.stanton.components.widgets.NavScaffold


@JsModule("highlight.js")
@JsNonModule
external val hljs: dynamic

external interface HighlightJsOptions {
    var language: String
}

class HighlightJsOptionsImp(override var language: String) : HighlightJsOptions

@Page
@Composable
fun HomePage() {
    NavScaffold {
        Row(
            Modifier
                .fillMaxWidth()
                .padding(2.cssRem),
            horizontalArrangement = Arrangement.Center
        ) {
            //language=kotlin
            KotlinCodeBlock(
                """
                interface Stanton {
                  suspend fun code(
                    thought: Thought,
                    music: Music, 
                    drink: Coffee, 
                  ): ElegantCode 
                }
            """.trimIndent()
            )
        }
        Box(
            Modifier
                .padding(bottom = 1.cssRem)
                .fontSize(25.pt)
        ) {
            Text("Recent posts:")
        }
        Ul {
            Li {
                NavLink("posts/bp_2024_06_14_hello", "Hello world")
            }
         /*   Li {
                NavLink("posts/bp_2024_08_11_where_it_all_began", "Where it all began")
            }*/
        }
    }
}


